export const User = {
    Auth: '/user/auth',
    GoogleAuth: '/user/googleauth',
    FacebookAuth: '/user/facebookauth',
    RefreshToken: '/user/refresh',
    Register: '/user/registerclient',
    ForgotPasswordSendCode: '/user/forgotpwdsendcode',
    ForgotPasswordConfirmCode: '/user/forgotpwdconfirm',
    ResetPassword: '/user/resetpassword',
    RevokeAccess: '/user/revoke',
    ConfirmEmail: '/user/confirmemail',
    ResendEmailConfirmationCode: '/user/resendconfirm',
    UpdateUser: '/user/update',
    DecryptConfirmationToken: '/user/decryptconfirmationtoken',
    List: '/user/list',
    Remove: '/user/remove',
    GetById: '/user/get'
};

export const Client = {
    Update: '/client/update',
    GetByUserId: '/client/getbyuserid',
    GetByPhoneNumber: '/client/getbyphonenumber',
    GetByCustomerId: '/client/getbycustomerid',
    GetWalletByUserId: '/client/getwalletbyuserid',
    GetWalletMinimum: '/client/getwithdrawminimum',
    GetCharities: '/client/getauthorizedcharities',
}

export const DropOffOrder = {
    CreateDropOffOrder: '/order/createdropoff'
}
export const Depot = {
    RegisterDepot: '/depot/register',
    PostalCodeList: '/depot/postalcodelist',
    SavePostalCode: '/depot/savepostalcode',
    SelectList: '/depot/selectlist',
    DepotList: '/depot/list',
    DepotMapList: '/depot/listmap',
    DisableDepot: '/depot/disable',
    EnableDepot: '/depot/enable',
    GetById: '/depot/getbyid',
    Update: '/depot/update',
    GetDepotInCoverage: '/depot/getdepotincoverage',
    GetBottleDrivesByDepot: '/depot/getbottledrivesbydepot',
    GetDepotName: '/depot/getdepotname',
};

export const Config = {
    GetDaysLimit: '/config/getdayslimit',
}

export const Schedule = {
    GetLimits: '/schedule/getlimits',
    GetPercentageLimit: '/config/getpercentagelimit',
}

export const Order = {
    CreateOrder: '/order/createorder',
    GetOrders: '/order/getorders',
    GetById: '/order/getbyid',
}
